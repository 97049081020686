import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  apiClientKeys,
  useApiClientCache,
} from "src/data/apiClients/apiClientQueryCache"
import {
  IApiClient,
  IApiClientBody,
  IApiClientPatchBody,
  IApiClientSecretResponse,
  IApiClientsResponse,
} from "src/data/apiClients/apiClientTypes"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function usePostApiClient() {
  const { invalidateClients } = useApiClientCache()

  async function postApiClient(body: IApiClientBody) {
    const response = await minutApiHttpClient.post<IApiClient>(
      `${API_DEFAULT}/clients`,
      body
    )

    return response.data
  }

  return useMutation<IApiClient, AxiosError, IApiClientBody>(postApiClient, {
    onSuccess: () => invalidateClients(),
  })
}

interface IPatchApiClientMutation {
  clientId: string
  body: IApiClientPatchBody
}
export function usePatchApiClient() {
  const { invalidateClients, invalidateClient } = useApiClientCache()

  async function patchApiClient({ body, clientId }: IPatchApiClientMutation) {
    const response = await minutApiHttpClient.patch<IApiClient>(
      `${API_DEFAULT}/clients/${clientId}`,
      body
    )

    return response.data
  }

  return useMutation<IApiClient, AxiosError, IPatchApiClientMutation>(
    patchApiClient,
    {
      onSuccess: (client) => {
        invalidateClients()
        invalidateClient(client.client_id)
      },
    }
  )
}

export function useDeleteApiClient() {
  const { invalidateClients } = useApiClientCache()

  async function deleteApiClient({ clientId }: { clientId: string }) {
    await minutApiHttpClient.delete(`${API_DEFAULT}/clients/${clientId}`)
  }

  return useMutation<void, AxiosError, { clientId: string }>(deleteApiClient, {
    onSuccess: () => invalidateClients(),
  })
}

export function useFetchApiClientSecret({
  clientId,
  options,
}: {
  clientId: string
  options?: UseQueryOptions<
    IApiClientSecretResponse,
    AxiosError,
    IApiClientSecretResponse,
    ReturnType<typeof apiClientKeys.secret>
  >
}) {
  async function fetchApiClientSecret(clientId: string) {
    const response = await minutApiHttpClient.get<IApiClientSecretResponse>(
      `${API_DEFAULT}/clients/${clientId}/secret`
    )
    return response.data
  }

  return useQuery(
    apiClientKeys.secret(clientId),
    () => fetchApiClientSecret(clientId),
    options
  )
}

export function useFetchApiClient({
  clientId,
  options,
}: {
  clientId: string
  options?: UseQueryOptions<
    IApiClient,
    AxiosError,
    IApiClient,
    ReturnType<typeof apiClientKeys.client>
  >
}) {
  async function fetchApiClient() {
    const response = await minutApiHttpClient.get<IApiClient>(
      `${API_DEFAULT}/clients/${clientId}`
    )
    return response.data
  }

  return useQuery(
    apiClientKeys.client(clientId),
    () => fetchApiClient(),
    options
  )
}

export function useFetchApiClients(params?: {
  limit?: number
  offset?: number
  owner_id?: string
  owner_type?: OwnerType
  client_id?: string
  options?: UseQueryOptions<
    IApiClientsResponse,
    AxiosError,
    IApiClientsResponse,
    ReturnType<typeof apiClientKeys.clients>
  >
}) {
  async function fetchApiClients() {
    const response = await minutApiHttpClient.get<IApiClientsResponse>(
      `${API_DEFAULT}/clients`,
      {
        params,
      }
    )
    return response.data
  }

  return useQuery(
    apiClientKeys.clients(params),
    fetchApiClients,
    params?.options
  )
}
