import { useQueryClient } from "@tanstack/react-query"

export const apiClientKeys = {
  all() {
    return ["apiClients"] as const
  },

  secret(clientId: string) {
    return [...this.all(), "secret", clientId] as const
  },

  clients(props?: {
    ownerId?: string
    ownerType?: string
    limit?: number
    offset?: number
  }) {
    if (props) {
      return [...this.all(), "client", "list", props] as const
    }
    return [...this.all(), "client", "list"] as const
  },

  client(clientId: string) {
    return [...this.all(), "client", clientId] as const
  },
}

export function useApiClientCache() {
  const queryClient = useQueryClient()

  function invalidateClients() {
    queryClient.invalidateQueries(apiClientKeys.clients())
  }

  function invalidateClient(clientId: string) {
    queryClient.invalidateQueries(apiClientKeys.client(clientId))
  }

  return {
    invalidateClients,
    invalidateClient,
  }
}
